<template>
  <div>
    <div class="video-container">
      <video playsinline autoplay muted loop poster="" id="bgvid">
        <source src="../assets/welcome/supa-mama-video.mp4" type="video/mp4" />
      </video>
    </div>

    <section
      id="about"
      class="about-section position-relative overflow-hidden ptb-100"
      v-bind:class="{ 'gray-light-bg': isGray }"
    >
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-md-6 col-lg-6">
            <div class="image-wrap">
              <img
                class="img-fluid banner-img"
                src="../assets/welcome/make-R-a-H-white.svg"
                alt="animation image"
              />
            </div>
          </div>
          <div class="col-md-12 col-lg-6">
            <div class="feature-contents section-heading">
              <div class="hero-content-left text-white py-5">
                <h1 class="text-white">Hey! <br>Looks like you're on a desktop!</h1>
                <p class="lead">
                  Navigate back to the site and install on your mobile phone for
                  the best experience.<br>
                </p>
                <div class="action-btns mt-4">
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <a
                        href="https://welcome.supamama.app/"
                        class="
                          d-flex
                          align-items-center
                          app-download-btn
                          btn btn-white btn-rounded
                        "
                      >
                        <div class="download-text text-left">
                          <small>Back to main site</small>
                          <h5 class="mb-0">Supa Mama</h5>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- <div class="row pt-4">
                <div class="col-4 col-lg-3 border-right">
                  <div class="count-data text-center">
                    <h4
                      class="count-number mb-0 color-primary font-weight-bold"
                    >
                      1023
                    </h4>
                    <span>Customers</span>
                  </div>
                </div>
                <div class="col-4 col-lg-3 border-right">
                  <div class="count-data text-center">
                    <h4
                      class="count-number mb-0 color-primary font-weight-bold"
                    >
                      5470
                    </h4>
                    <span>Downloads</span>
                  </div>
                </div>
                <div class="col-4 col-lg-3 border-right">
                  <div class="count-data text-center">
                    <h4
                      class="count-number mb-0 color-primary font-weight-bold"
                    >
                      3560
                    </h4>
                    <span>Satisfied</span>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "MobileRedirect",
  props: {
    isGray: {
      type: Boolean,
      default: false,
    },
  },
  // methods: {
  //   desktopAppRedirect () {
  //   this.$gtag.event('desktopAppRedirect', { method: 'Google' })
  //   }
  // }
};
</script>

<style scoped>
.banner-img {
  max-width: 65% !important;
}
</style>