var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"work-process-section position-relative",class:{
    'gray-light-bg': _vm.isGray,
    'pb-100': _vm.paddingBottom,
    'pt-100': _vm.paddingTop,
  },attrs:{"id":"process"}},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row align-items-center justify-content-md-center justify-content-sm-center"},[_c('div',{staticClass:"col-md-12 col-lg-6"},[_c('div',{staticClass:"work-process-wrap"},[_c('div',{staticClass:"process-single-item"},[_c('div',{staticClass:"process-icon-item left-shape"},[_vm._m(1),_c('svg',{attrs:{"x":"0px","y":"0px","width":"312px","height":"130px"}},[_c('path',{staticClass:"dashed1",attrs:{"fill":"none","stroke":"rgb(95, 93, 93)","stroke-width":"1","stroke-dasharray":"1300","stroke-dashoffset":"0","d":"M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338"}}),_c('path',{staticClass:"dashed2",attrs:{"fill":"none","stroke":"#ffffff","stroke-width":"2","stroke-dasharray":"6","stroke-dashoffset":"1300","d":"M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338 "}})])])]),_c('div',{staticClass:"process-single-item"},[_c('div',{staticClass:"process-icon-item right-shape"},[_vm._m(2),_c('svg',{attrs:{"x":"0px","y":"0px","width":"312px","height":"130px"}},[_c('path',{staticClass:"dashed1",attrs:{"fill":"none","stroke":"rgb(95, 93, 93)","stroke-width":"1","stroke-dasharray":"1300","stroke-dashoffset":"0","d":"M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"}}),_c('path',{staticClass:"dashed2",attrs:{"fill":"none","stroke":"#ffffff","stroke-width":"2","stroke-dasharray":"6","stroke-dashoffset":"1300","d":"M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"}})])])]),_c('div',{staticClass:"process-single-item"},[_c('div',{staticClass:"process-icon-item left-shape mb-0"},[_c('div',{staticClass:"d-flex align-items-center"},[_vm._m(3),_vm._m(4),_c('svg',{attrs:{"x":"0px","y":"0px","width":"312px","height":"130px"}})])])])])]),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-9 col-lg-8"},[_c('div',{staticClass:"section-heading text-center mb-5"},[_c('h2',[_vm._v("Get started!")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"process-icon mr-4"},[_c('img',{staticStyle:{"width":"120px"},attrs:{"src":require("../../../assets/welcome/phone-1.svg"),"alt":"image"}})]),_c('div',{staticClass:"process-content text-left"},[_c('h5',[_vm._v("1. Install the App")]),_c('p',[_vm._v(" Download the app on your mobile so that you have easy access to it and fill in your details so that we can give you the best experience possible. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"process-icon ml-4"},[_c('img',{staticClass:"img-fluid",staticStyle:{"width":"100px"},attrs:{"src":require("../../../assets/welcome/phone-2.svg"),"alt":"image"}})]),_c('div',{staticClass:"process-content text-right"},[_c('h5',[_vm._v("2. Learn")]),_c('p',[_vm._v(" You will be able to find great information on the Recyclopedia, like guides on how to recycle, info on what gets done with your recycling and options and insights into how you can be a part of the solution. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"process-icon mr-4"},[_c('img',{staticClass:"img-fluid",staticStyle:{"width":"120px"},attrs:{"src":require("../../../assets/welcome/phone-3.svg"),"alt":"image"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"process-content text-left"},[_c('h5',[_vm._v("3. Act")]),_c('p',[_vm._v(" Find a depot near you to drop your recycling off or upgrade your plan and have the added convenience of your recycling being collected from your door. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 col-lg-6"},[_c('div',{staticClass:"img-wrap"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../../../assets/welcome/phone-4.svg"),"alt":"features"}})])])
}]

export { render, staticRenderFns }