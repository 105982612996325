<template>
    <header class="header">
        <nav class="navbar navbar-expand-lg fixed-top" v-bind:class="{ affix: hasAffix, 'custom-nav': coloredLogo, 'bg-transparent': !coloredLogo }">
            <div class="container">
                <a class="navbar-brand" href="/">
                    <img style="height: 60px;"
                        src="./../../assets/SM-RECYCLING-LOGO-t-w.svg"
                        alt="logo"
                        class="img-fluid"
                    />
                </a>
                <button class="navbar-toggler" type="button" @click="mobileNavClicked" v-bind:class="{ 'collapsed': collapsed }">
                    <span class="ti-menu"></span>
                </button>
                <div class="collapse navbar-collapse h-auto" v-bind:class="{ 'show': !collapsed }" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto menu">
                        <li>
                            <a href="/">About the App</a>
                        </li>
                        <!-- <li>
                            <router-link to="#about" v-scroll-to="'#about'">About</router-link>
                        </li> -->
                        <li>
                            <router-link to="#features" v-scroll-to="'#features'">Features</router-link>
                        </li>
                        <!-- <li>
                            <router-link to="#screenshots" v-scroll-to="'#screenshots'">Screenshots</router-link>
                        </li> -->
                        <li>
                            <router-link to="#process" v-scroll-to="'#process'">How it works</router-link>
                        </li>
                        <!-- <li>
                            <router-link to="#pricing" v-scroll-to="'#pricing'">Subscriptions</router-link>
                        </li> -->
                        <!-- <li>
                            <router-link to="#footer" v-scroll-to="'#footer'">Contact</router-link>
                        </li> -->
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
    export default {
        props: {
            coloredLogo: {
                type: Boolean,
                default: false
            }
        },
        data: function() {
            return {
                windowTop: 0,
                collapsed: true
            }
        },
        mounted() {
            window.addEventListener("scroll", this.onScroll)
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.onScroll)
        },
        methods: {
            onScroll: function() {
                this.windowTop = window.top.scrollY
            },
            mobileNavClicked: function() {
                this.collapsed = !this.collapsed;
            }
        },
        computed: {
            hasAffix: function () {
                return this.windowTop > 0;
            }
        }
    }
</script>

<style scoped>

.header .menu li a { color: #ffffff !important;
        font-weight: 600 !important;
    }

</style>