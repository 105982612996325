<template>
    <section class="promo-section ptb-100" v-bind:class="{ 'gray-light-bg' : isGray }">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-10 col-lg-8">
                    <div class="section-heading text-center">
                        <h2>Why should you get the Supa Mama App?</h2>
                        <p>Supa Mama contributes to the development of women in South Africa through opportunities in recycling, fashion, manufacturing and education. 
                            In addition to the positive community impact, there are many reasons to recycle properly.</p>
                        <p>Start today and join the Supa Mama App community.</p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-md-center justify-content-sm-center">
                <div class="col-md-6 col-lg-4">
                    <div class="card border-0 single-promo-card single-promo-hover text-center p-2 mt-4">
                        <div class="card-body">
                            <div class="pb-2">
                                <!-- <span class="fas fa-cubes icon-size-lg color-primary"></span> -->
                                <img class="img-fluid img-size" src="../../assets/welcome/info-G-1kg.svg" alt="animation image">
                            </div>
                            <div class="pt-2 pb-3">
                                <!-- <h5>Mission</h5>
                                <p class="mb-0">text.</p> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="card border-0 single-promo-card single-promo-hover text-center p-2 mt-4">
                        <div class="card-body">
                            <div class="pb-2">
                                <img class="img-fluid img-size" src="../../assets/welcome/info-G-sea.svg" alt="animation image">
                            </div>
                            <div class="pt-2 pb-3">
                                <!-- <h5>Aspirations</h5>
                                <p class="mb-0">text.</p> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <div class="card border-0 single-promo-card single-promo-hover text-center p-2 mt-4">
                        <div class="card-body">
                            <div class="pb-2">
                                <img class="img-fluid img-size" src="../../assets/welcome/info-G-world-bin.svg" alt="animation image">
                            </div>
                            <div class="pt-2 pb-3">
                                <!-- <h5>Impact</h5>
                                <p class="mb-0">text.</p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
    export default {
        name: 'Promo',
        props: {
            isGray: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style scoped>

.img-size {
    max-width: 100%;
}
</style>