<template>
  <div id="features" class="feature-section ptb-100" v-bind:class="{ 'gray-light-bg': isGray }">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-9 col-lg-9">
          <div class="section-heading text-center mb-5">
            <h2>Supa Features</h2>
            <p>A community platform created for effective recycling</p>
          </div>
        </div>
      </div>

      <!--feature new style start-->
      <div class="row align-items-center justify-content-md-center">
        <div class="col-lg-4 col-md-12">
          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                <span class="mr-4">
                  <img src="../../assets/welcome/tips-lightbulb.svg" alt="logo" style="height: 50px !important;"/>
                </span>
                <div class="icon-text">
                  <h5 class="mb-2">Collections (Coming Soon)</h5>
                  <p>We collect your recycling from your home or business</p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                <span class="mr-4">
                  <img src="../../assets/welcome/tips-lightbulb.svg" alt="logo" style="height: 50px;"/>
                </span>
                <div class="icon-text">
                  <h5 class="mb-2">Tips & Tricks</h5>
                  <p>Learn how to recycle properly</p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                <span class="mr-4">
                  <img src="../../assets/welcome/tips-lightbulb.svg" alt="logo" style="height: 50px;"/>
                </span>
                <div class="icon-text">
                  <h5 class="mb-2">Depot finder</h5>
                  <p>Locate a depot near you with ease</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-5 d-none d-sm-none d-md-block d-lg-block">
          <div class="position-relative pb-md-5 py-lg-0">
            <img
              alt="Supa Mama Recycling"
              src="../../assets/welcome/make-R-a-H.svg"
              class="img-center img-fluid"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                <span class="mr-4">
                  <img src="../../assets/welcome/tips-lightbulb.svg" alt="logo" style="height: 50px !important;"/>
                </span>
                <div class="icon-text">
                  <h5 class="mb-2">Make an impact</h5>
                  <p>Support a meaningful cause</p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                <span class="mr-4">
                  <img src="../../assets/welcome/tips-lightbulb.svg" alt="logo" style="height: 50px !important;"/>
                </span>
                <div class="icon-text">
                  <h5 class="mb-2">Make moves</h5>
                  <p>Contribute to a circular economy.</p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                <span class="mr-4">
                  <img src="../../assets/welcome/tips-lightbulb.svg" alt="logo" style="height: 50px !important;"/>
                </span>
                <div class="icon-text">
                  <h5 class="mb-2">Make friends</h5>
                  <p>Be a part of a meaningful community</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--feature new style end-->
    </div>
  </div>
</template>

<script>
export default {
  name: "Features",
  props: {
    isGray: {
      type: Boolean,
      default: false
    },
  },
};
</script>

<style>
</style>