<template>
    <!--footer section start-->
    <!--when you want to remove subscribe newsletter container then you should remove .footer-with-newsletter class-->
    <footer class="footer-1 supa-green ptb-60" v-bind:class="{ 'footer-with-newsletter': showSubscribe}">
        <!--subscribe newsletter start-->
        <div class="container" v-show="showSubscribe">
            <div class="row newsletter-wrap primary-bg rounded shadow-lg p-5">
                <div class="col-md-6 col-lg-7 mb-4 mb-md-0 mb-sm-4 mb-lg-0">
                    <div class="newsletter-content text-white">
                        <h3 class="mb-0 text-white">Subscribe our Newsletter</h3>
                        <p class="mb-0">We’re a team of non-cynics who truly care for our work.</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-5">
                    <form class="newsletter-form position-relative">
                        <input
                                type="text"
                                class="input-newsletter form-control"
                                placeholder="Enter your email"
                                name="email"
                                required
                                autocomplete="off"
                        />
                        <button type="submit" class="disabled">
                            <i class="fas fa-paper-plane"></i>
                        </button>
                    </form>
                </div>
            </div>
        </div>
        <!--subscribe newsletter end-->

        <div class="container">
            <div class="row">
                <div class="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
                    <a href="#" class="navbar-brand mb-2">
                        <img src="../../assets/SM-RECYCLING-LOGO-t.svg" alt="logo" class="img-fluid" style="height: 90px;"/>
                    </a>
                    <br />
                    <!-- <p class="white-text">Follow Supa Mama</p> -->
                    <div class="list-inline social-list-default background-color social-hover-2 mt-2">
                        <!-- <li class="list-inline-item">
                            <a class="twitter" href="#">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li> -->
                        <!-- <li class="list-inline-item">
                            <a class="youtube" href="#">
                                <i class="fab fa-youtube"></i>
                            </a>
                        </li> -->
                        <!-- <li class="list-inline-item">
                            <a class="linkedin" href="#" target="_blank">
                                <i class="fab fa-linkedin-in"></i>
                            </a>
                        </li> -->
                        <!-- <li class="list-inline-item">
                            <a class="facebook" href="https://www.facebook.com/SupaMamaSA" target="_blank">
                                <i class="fab fa-facebook"></i>
                            </a>
                        </li>
                         <li class="list-inline-item">
                            <a class="instagram" href="https://www.instagram.com/SupaMama_SA/" target="_blank">
                                <i class="fab fa-instagram"></i>
                            </a>
                        </li> -->
                    </div>
                </div>
                <div class="col-md-12 col-lg-8">
                    <div class="row mt-0">
                        <div class="col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                            <h6 class="text-uppercase white-text">Cause</h6>
                            <ul>
                                <li>
                                    <a href="https://www.supamama.co.za/invest-in-dignity/" target="_blank">Invest in Diginity</a>
                                </li>
                                <li>
                                    <a href="mailto:info@supamama.co.za" target="_blank">Contact</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                            <h6 class="text-uppercase white-text">Products</h6>
                            <ul>
                                <li>
                                    <a href="https://www.supamama.co.za/products/" target="_blank">Refuse</a>
                                </li>
                                <li>
                                    <a href="https://www.supamama.co.za/products/" target="_blank">Food</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                            <h6 class="text-uppercase white-text">Company</h6>
                            <ul>
                                <li>
                                    <a href="https://www.supamama.co.za/about/" target="_blank">About Us</a>
                                </li>
                                <li>
                                    <a href="https://www.supamama.co.za/contact/" target="_blank">Report a problem</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-sm-6 col-md-3 col-lg-3">
                            <h6 class="text-uppercase white-text">Socials</h6>
                            <div class="list-inline social-list-default background-color social-hover-2 mt-2">
                        <li class="list-inline-item">
                            <a class="facebook" href="https://www.facebook.com/SupaMamaSA" target="_blank">
                                <i class="fab fa-facebook"></i>
                            </a>
                        </li>
                         <li class="list-inline-item">
                            <a class="instagram" href="https://www.instagram.com/SupaMama_SA/" target="_blank">
                                <i class="fab fa-instagram"></i>
                            </a>
                        </li>
                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--end of container-->
    </footer>


</template>

<script>
    export default {
        name: 'SiteFooter',
        props: {
            showSubscribe: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style scoped>

.supa-green {
    background-color: #f4b91e !important;
    }

footer a {
        color: #ffffff !important;
        font-weight: 500 !important;
    }

.white-text {
        color: #ffffff !important;
        font-weight: 600 !important;
    }

</style>